import styled, { createGlobalStyle } from 'styled-components';
import Button from './components/button';
import { NEUTRAL_GRAY_600, WHITE_SMOKE } from './ui/colors';

export const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
  }

  #root-unico-idpay-ecommerce {
    background: #eeeeee;
    height: 100%;
    display: flex;
  }

  .swal2-styled {
    background: none !important;
    box-shadow: none !important;
    padding: 0 !important;
    outline: none !important;
  }

  .swal2-html-container {
    color: #767676;
    font-size: 14px;
    margin: 0 0 14px 0;
    padding: 8px 24px;
  }

  .swal2-html-container p {
    margin: 0;
  }

  .swal2-title p {
    color: #131516!important;
    font-size: 18px;
    font-weight: 500;
    margin: 0;
  }

  .swal2-title img {
    margin-bottom: 16px;
  }

  .swal2-actions {
    margin-top: 0;
  }
`;

export const PageTitle = styled.h1`
  color: ${NEUTRAL_GRAY_600};
  font-size: 20px;
  line-height: 30px;
  width: 100%;
  display: inline-block;
  margin-top: 16px;

  @media screen and (min-width: 768px) {
    max-width: initial;
  }
`;

export const PageSubtitle = styled.p`
  font-size: 16px;
  line-height: 24px;
  width: 100%;
  padding: 8px;
`;
export const BtnSelectDevice = styled(Button)`
  padding: 15px !important;
  font-weight: normal;
  gap: 5px;

  & svg {
    width: 25px;
  }
`;

export const SelectDeviceArea = styled.div`
  display: flex;
  gap: 8px;
  width: 100%;

  @media screen and (max-width: 450px) {
    & {
      flex-direction: column;
    }
  }
`;

export const LoadingWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  position: absolute;
  background: ${WHITE_SMOKE};
  top: 0;
  width: 100%;

  & span {
    font-size: 24px;
  }

  & p {
    font-size: 12px;
    margin-top: 8px;
  }
`;


const words = {
  Status: 'Status',
  Company: 'Empresa',
  Companies: 'Empresas',
  Value: 'Valor',
  Date: 'Data',
  Transaction: 'Transação',
  Transactions: 'Transações',
  Today: 'Hoje',
  Prev: 'Anterior',
  Next: 'Próximo',
  Waiting: 'Aguardando',
  Processing: 'Processando',
  Canceled: 'Cancelado',
  CanceledPlural: 'Cancelados',
  Expired: 'Expirado',
  ExpiredPlural: 'Expirados',
  Approved: 'Aprovado',
  ApprovedPlural: 'Aprovados',
  Inconclusive: 'Inconclusivo',
  Inconclusives: 'Inconclusivos',
  Refused: 'Recusado',
  RefusedPlural: 'Recusados',
  Shared: 'Compartilhado',
  SharedPlural: 'Compartilhados',
  Apply: 'Aplicar',
  Cancel: 'Cancelar',
  Report: 'Relatório',
  Reports: 'Relatórios',
  Panel: 'Painel',
  Settings: 'Configurações',
  Logout: 'Logout',
  final: 'final',
  Hello: 'Olá',
  Information: 'Informação',
  Back: 'Voltar',
  Confirm: 'Confirmar',
  Continue: 'Continuar',
  seconds: 'segundos',
}

const errors = {
  "MSG_ERROR_40024": "O nome informado não é válido",
  "MSG_ERROR_40021": "O telefone informado não é válido",
  "MSG_ERROR_40022": "O email informado não é válido",
  "MSG_ERROR_40027": "Já foi enviada uma transação com esse número do pedido",
  "MSG_ERROR_40045": "O valor da transação ultrapassa os limites aceitos",
  "MSG_ERROR_40012": "Não conseguimos autenticar a foto. Tente novamente.",
  "MSG_ERROR_DEFAULT": "Ops! Algo Inesperado aconteceu. Tente novamente dentro de alguns segundos.",
  "MSG_ERROR_CREATE_TRANSACTION": "Não foi possível criar a transação.",
}

const buttons = {
  owner: 'Sou o titular',
}

export default {
  translations: {
    ...words,
    ...errors,
    ...buttons,
    'BodyEvidence': 'Conjunto Probatório',
    'chooseCompany': 'Escolha a empresa',
    'footer.privacyPolicy': 'Política de privacidade',
    'footer.allRightsReserved': 'Todos os direitos reservados',
    'footer.lastUpdate': 'Última atualização em',
    'filter.selectPeriod': 'Selecionar período',
    'button.newAutentication': 'Nova autenticação',
    'search.placeholder': 'N° do pedido ou CPF',
    'transactions.title': words.Transactions,
    'transactions.dateDays': '{{days}} dias',
    'transactions.noPermission': 'Usuário sem permissão de ver as transações de',
    'transactions.columns.orderNumber': 'N° pedido',
    'transactions.columns.company': words.Company,
    'transactions.columns.companyKind': 'Tipo de uso',
    'transactions.columns.identificationDoc': 'CPF',
    'transactions.columns.value': words.Value,
    'transactions.columns.cardNumber': 'Cartão de Crédito',
    'transactions.columns.date': words.Date,
    'transactions.columns.status': words.Status,
    'transaction.status.processHeader.filterLabel': 'Status do <strong>processo</strong>',
    'transaction.status.responseHeader.filterLabel': 'Status de <strong>resposta</strong>',
    'transaction.status.userActionsHeader.filterLabel': 'Status de <strong>ações do usuário</strong>',
    'transaction.status.approved.label': words.Approved,
    'transaction.status.approved.filterLabel': words.ApprovedPlural,
    'transaction.status.canceled.label': words.Canceled,
    'transaction.status.canceled.filterLabel': words.CanceledPlural,
    'transaction.status.absentHolder.label': 'Titular ausente',
    'transaction.status.absentHolder.filterLabel': 'Titular ausente',
    'transaction.status.disapproved.label': words.Refused,
    'transaction.status.disapproved.filterLabel': words.RefusedPlural,
    'transaction.status.inconclusive.label': words.Inconclusive,
    'transaction.status.inconclusive.filterLabel': words.Inconclusives,
    'transaction.status.fastInconclusive.label': 'Inconclusivo rápido',
    'transaction.status.fastInconclusive.filterLabel': 'Inconclusivos rápido',
    'transaction.status.processing': words.Processing,
    'transaction.status.shared.label': words.Shared,
    'transaction.status.shared.filterLabel': words.SharedPlural,
    'transaction.status.waiting': words.Waiting,
    'transaction.status.skiped': 'Pulou biometria',
    'transaction.status.unknownShare.label': 'Não reconhece',
    'transaction.status.unknownShare.filterLabel': 'Não reconhece compra',
    'transaction.status.expired.label': words.Expired,
    'transaction.status.expired.filterLabel': words.ExpiredPlural,
    'transaction.chargeback.none': 'Sem pedido',
    'transaction.chargeback.analyzing.label': 'Em análise',
    'transaction.chargeback.analyzing.detailLabel': 'Análise em andamento...',
    'transaction.chargeback.waiting.label': 'Aguardando análise',
    'transaction.chargeback.waiting.detailLabel': 'Aguardando análise...',
    'transaction.chargeback.refused.label': 'Chargeback negado',
    'transaction.chargeback.refused.detailLabel': 'Negado',
    'transaction.chargeback.approved.label': 'Chargeback aprovado',
    'transaction.chargeback.approved.detailLabel': words.Approved,

    /* Dashboard */
    'dashboard.warning': 'Algumas transações ainda estão em processamento, por isso os números sofrerão alterações ao longo do dia',
    'dashboard.transactions.approved': 'Transações aprovadas',
    'dashboard.transactions.eligible': 'Transações elegíveis',
    'dashboard.transactions.received': 'Transações recebidas',
    'dashboard.transactions.viewAll': 'Ver todas transações',
    'dashboard.value.approved': 'Valor aprovado',
    'dashboard.value.received': 'Valor recebido',
    'dashboard.percentage.opened': 'Taxa de abertura',
    'dashboard.percentage.captured': 'Taxa de captura',
    'dashboard.approval.resume': 'Aprovação total',
    'dashboard.warning.maxFilterByDate': 'Não é possível filtrar um período maior que {{max}} dias',

    /* Issuer Portal */
    'issuer.transactions.columns.company': 'Estabelecimento',
    'issuer.transactions.columns.date': 'Data da transação',
    'issuer.transactions.columns.consult': 'Consulta',

    /* Card Consult */
    'card-consult.approved': 'Válido',
    'card-consult.unsure': 'Inválido',
    'card-consult.waiting': 'Aguardando',

    /* Modal Notify Chargeback */
    'transaction.modal.notifyChargeback.filesRequired': 'A comprovação do chargeback é obrigatória',
    'transaction.modal.notifyChargeback.fail': 'Não foi possível enviar a contestação',
    'transaction.modal.notifyChargeback.success': 'Contestação enviada',
    'transaction.modal.notifyChargeback.cancel': words.Cancel,
    'transaction.modal.notifyChargeback.confirm': words.Confirm,
    'transaction.modal.notifyChargeback.title': 'Nova contestação',

    'transaction.modal.notifyChargeback.form.chargebackDate': 'Data da contestação*',
    'transaction.modal.notifyChargeback.form.document': 'CPF da pessoa que contestou*',
    'transaction.modal.notifyChargeback.form.name': 'Nome da pessoa que contestou*',
    'transaction.modal.notifyChargeback.form.reason': 'Motivo da contestação*',
    'transaction.modal.notifyChargeback.form.obs': 'Observações',
    'transaction.modal.notifyChargeback.form.attachEvidences': 'Anexar evidências*',
    'transaction.modal.notifyChargeback.form.attachEvidencesObs': 'Obs.: a comprovação do chargeback é obrigatória',
    'transaction.modal.notifyChargeback.form.file': 'Anexar evidências*',

    'transaction.modal.notifyChargeback.upload.attachEvidencesObs': 'Obs.: a comprovação do chargeback é obrigatória',
    'transaction.modal.notifyChargeback.upload.label': 'Anexe aqui o documento',
    'transaction.modal.notifyChargeback.upload.failLabel': 'Não foi possível enviar o arquivo de contestação',
    'transaction.modal.notifyChargeback.upload.successLabel': 'Arquivo de contestação enviados com sucesso',
    'transaction.modal.notifyChargeback.upload.ruleMaxFilesMessage': 'A quantidade máxima de evidências foi atingida',

    /* Manual Workflow Screens */
    'sms.footer.textPrivacyPolicy': 'Caso deseje saber mais sobre como tratamos e porque coletamos seus dados pessoais, confira nossa ',
    'sms.footer.termPrivacyPolicy': 'Política de Privacidade.',
    'sms.footer.altImgSafe': 'Ambiente seguro',
    'sms.orderSummary.company': 'Local da compra',
    'sms.orderSummary.value': 'Valor total da compra',
    'sms.orderSummary.creditCard': 'Cartão de crédito',
    'sms.orderSummary.captureDate': 'Quando a selfie foi tirada',
    'sms.orderSummary.title': 'Dados da compra',
    'sms.registerSummary.title': 'Dados do cadastro',
    'sms.registerSummary.company': 'Local do cadastro',
    'sms.registerSummary.creditCard': 'Cartão cadastrado',
    'sms.tips.title': 'Dicas de captura',
    'sms.tips.cameraAccess': 'Permita o acesso à câmera',
    'sms.tips.areaLit': 'Encontre uma área bem iluminada',
    'sms.tips.phoneEye': 'Segure o telefone no nível dos olhos',
    'sms.tips.removeGlasses': 'Retire óculos, máscara, chapéu e fones',
    'sms.notify.presentation.title.simplified': '<strong>{{name}}</strong>, para validar sua compra, precisamos de uma selfie do titular do cartão com o <strong>final {{lastDigits}}</strong>',
    'sms.notify.presentation.title.contextful': 'Olá, <strong>{{name}}</strong><br />Precisamos de uma selfie do titular do cartão de crédito.',
    'sms.notify.presentation.subtitle.simplified': 'Caso não seja você, peça para o dono tirar a foto',
    'sms.notify.presentation.subtitle.contextful': 'Queremos garantir se essa compra é verdadeira e reconhecida por você.',
    'sms.notify.presentation.buttonMobile.owner.simplified': 'Sou o titular, tirar selfie',
    'sms.notify.presentation.buttonMobile.owner.contextful': buttons.owner,
    'sms.notify.presentation.button.share.simplified': 'Pedir selfie para o titular do cartão',
    'sms.photoAlreadyTaken': 'Já tirei a foto',
    'sms.buttonShare': 'Enviar para o titular',
    'sms.buttonCopyLink': 'Copiar link',
    'sms.notify.presentation.button.educationSelfie': 'Entenda por que precisamos da selfie',
    'sms.modalSelfieExplain.title': 'Por que tirar a selfie?',
    'sms.modalSelfieExplain.text1': 'Precisamos da <strong>confirmação da compra</strong> pelo <strong>titular</strong> do cartão utilizado.',
    'sms.modalSelfieExplain.text2': 'Essa validação serve tanto para proteger o titular do cartão quanto garantir a realização da compra.',
    'sms.modalSelfieExplain.confirmButton': 'Ok, entendi',
    'sms.notify.confirmOwnerData.title': 'Confirme o CPF do titular do cartão',
    'sms.notify.confirmOwnerData.button.CPFExplain': 'Entenda por que precisamos do CPF',
    'sms.notify.confirmOwnerData.labelCPF': 'Número do CPF',
    'sms.notify.confirmOwnerData.modalCPFExplain.title': 'Por que pedimos o CPF?',
    'sms.notify.confirmOwnerData.modalCPFExplain.text1': 'Nós precisamos autenticar a identidade do titular do cartão de crédito para garantir uma maior segurança nessa compra.',
    'sms.notify.confirmOwnerData.modalCPFExplain.text2': 'O titular <strong>é a pessoa dona do CPF</strong> que está associado ao <strong>nome impresso no cartão</strong>.',
    'sms.notify.confirmOwnerData.modalCPFExplain.confirmButton': 'Ok, entendi',
    'sms.wallet.integrated.notify.presentation.title': 'Precisamos de uma <strong>selfie do titular</strong> do cartão cadastrado',
    'sms.wallet.integrated.notify.presentation.subtitle': 'É importante que a foto seja do <strong>titular do<br/>CPF {{cpf}}</strong>',
    'sms.wallet.integrated.notify.presentation.buttonMobile.owner': 'Sou o titular do cartão',
    'sms.wallet.integrated.notify.presentation.buttonMobile.takeSelfie': 'Tirar selfie',
    'sms.wallet.integrated.notify.presentation.buttonMobile.share': 'Compartilhar com o titular',
    'sms.wallet.integrated.notify.sharingVerification.title': 'Você <strong>compartilhou</strong> o link com<br/>o titular do cartão',
    'sms.wallet.integrated.notify.sharingVerification.subtitle': 'A verificação está pendente até a foto ser tirada',
    'sms.wallet.integrated.notify.sharingVerification.buttonMobile.share': 'Compartilhar o link novamente',
    'sms.wallet.integrated.notify.sharingVerification.buttonMobile.owner': 'Seguir verificação por aqui',
    'sms.wallet.integrated.share.presentation.title': 'Precisamos de uma <strong>selfie do titular</strong> do cartão de <strong>final {{lastDigits}}</strong>',
    'sms.wallet.integrated.share.presentation.subtitle': 'Estamos realizando a verificação do cadastro do cartão de crédito na carteira digital',
    'sms.wallet.integrated.share.presentation.buttonMobile.allowCameraAccess': 'Permitir acesso à câmera',
    'sms.wallet.integrated.share.presentation.buttonMobile.doNotRecognize': 'Não reconheço esse cadastro',
    'sms.wallet.integrated.share.congratulations.title': 'Selfie <strong>enviada</strong> com sucesso!',
    'sms.wallet.integrated.share.congratulations.subtitle': 'Agora é só <strong>aguardar a verificação</strong> ser finalizada',
    'sms.validateWaitMsg': 'Estamos analisando suas informações',
    'sms.waitMsg': 'Aguarde um momento',
    'sms.sharedSteps.alreadyShared.title': 'Você compartilhou o link com o titular do cartão',
    'sms.sharedSteps.alreadyShared.subtitle': 'A verificação está pendente até a foto ser capturada.',
    'sms.sharedSteps.alreadyShared.labelOwner': 'O titular está próximo a você?',
    'sms.sharedSteps.alreadyShared.buttonContinueCapture': 'Continuar captura',
    'sms.wallet.registerNotRecognized.title': 'Cadastro não reconhecido',
    'sms.wallet.registerNotRecognized.subtitle': 'Estamos <strong>informando o estabelecimento</strong> sobre o cadastro indevido',
    'sms.sharedSteps.congratulations.title': 'Selfie tirada com sucesso',
    'sms.sharedSteps.congratulations.subtitle': 'Agora é só aguardar o retorno da {{company}} sobre a compra.',
    'sms.sharedSteps.congratulationsReturnToDevice.title': 'Selfie tirada com sucesso',
    'sms.sharedSteps.congratulationsReturnToDevice.subtitle': 'Para finalizar, volte ao dispositivo inicial.',
    'sms.sharedSteps.error.title': 'Transação não existente',
    'sms.sharedSteps.error.subtitle': 'Para mais informações entre em contato com o estabelecimento da sua compra.',
    'sms.sharedSteps.expired.title': 'Esse link está expirado!',
    'sms.sharedSteps.expired.subtitle': 'Para mais informações entre em contato com o estabelecimento da sua compra.',
    'sms.sharedSteps.orderNotRecognized.title': 'Compra não reconhecida',
    'sms.sharedSteps.orderNotRecognized.subtitle': 'Estamos informando o estabelecimento sobre a compra indevida',
    'sms.sharedSteps.prepare.title': 'Prepare-se para tirar a selfie',
    'sms.sharedSteps.prepare.subtitle': 'A selfie deve ser do titular do CPF',
    'sms.sharedSteps.prepare.subtitleWithCPF': 'A selfie deve ser do titular do CPF: {{cpf}}',
    'sms.sharedSteps.prepare.subtitleWithInstruction': 'Vamos usar a foto para confirmar sua identidade. Por isso:',
    'sms.sharedSteps.prepare.msgWait': 'Estamos nos preparando para <br />capturar sua selfie',
    'sms.sharedSteps.prepare.buttonCaptureSelfie': 'Permitir acesso à câmera',
    'sms.sharedSteps.prepare.buttonShare': 'Pedir selfie para o titular do cartão',
    'sms.sharedSteps.QRCode.title': 'Aponte a câmera do seu celular para o QRCode ou copie o link',
    'sms.sharedSteps.QRCode.subtitle': 'Após tirar a foto, <strong>volte para finalizar</strong> a validação nesta página',
    'sms.sharedSteps.QRCode.warning': 'Não feche esta página enquanto captura a foto',
    'sms.sharedSteps.QRCode.desktopError.title': 'Não conseguimos acessar sua câmera',
    'sms.sharedSteps.QRCode.desktopError.subtitle': 'Continue essa transação em outro dispositivo. Aponte a câmera do seu celular para o QR Code ou compartilhe o link clicando no botão abaixo.',
    'sms.sharedSteps.QRCode.buttonCopyLink': '<strong>Copie o link</strong> para tirar a foto em outro dispositivo',
    'sms.sharedSteps.sharingVerification.title': 'Você <strong>compartilhou</strong> o link com o titular do cartão',
    'sms.sharedSteps.sharingVerification.subtitle': 'A verificação está pendente até a foto ser tirada',
    'sms.sharedSteps.sharingVerification.selfieExplain': 'A selfie precisa ser da pessoa dona do cartão.',
    'sms.sharedSteps.sharingVerification.alreadyShared': 'Voltar para a loja',
    'sms.sharedSteps.sharingVerification.finishVerification': 'Finalizar verificação',
    'sms.sharedSteps.sharingVerification.owner': 'Seguir verificação por aqui',
    'sms.sharedSteps.sharingVerification.explain': 'Se você não for a pessoa dona do cartão, é só compartilhar o link com o titular',
    'sms.sharedSteps.sharingVerification.buttonShare': 'Compartilhar o link com o titular',
    'sms.sharedSteps.verificationFinished.title': 'Verificação finalizada',
    'sms.sharedSteps.verificationFinished.subtitle': 'A loja irá seguir com a verificação diretamente por lá',
    "sms.showErrorIcon": "Ícone de alerta",
    'sms.copyLink.title': 'Link copiado',
    'sms.copyLink.msg': 'O link foi copiado para que possa tirar a foto em outro dispositivo',
    "sms.share.auth.title": "Precisamos de uma selfie do titular do cartão de crédito",
    "sms.share.auth.subtitle": "Autorize a compra que está sendo feita através da foto.",
    "sms.share.auth.fieldName": "Nome escrito no cartão",
    "sms.share.auth.fieldCPF": "Número do CPF",
    "sms.validateCPF": "Digite o CPF completo",
    "sms.share.auth.buttonOwner": "Tirar selfie",
    'sms.button.photoWebcam': 'Tirar foto com webcam',
    'sms.button.photoMobile': 'Tirar foto com celular',
    'sms.button.noWebcam': 'Não tenho webcam',
    'sms.button.unknown': 'Não reconheço essa compra',
    'sms.button.information': 'Por que recebi esse link?',
    'sms.button.notOwner': 'Não sou o titular',
    'sms.button.notOwner.label': 'Você é o titular do cartão utilizado?',
    'sms.modal.orderNotRecognized.title': 'Tem certeza que não reconhece essa compra?',
    'sms.modal.orderNotRecognized.text': 'Ao seguir, iremos avisar o estabelecimento sua confirmação.',
    'sms.modal.orderNotRecognized.confirmButton': 'Não reconheço a compra',
    'sms.wallet.modal.notRecognized.title': 'Tem certeza que não reconhece esse cadastro?',
    'sms.wallet.modal.notRecognized.text': 'Ao seguir, iremos avisar ao estabelecimento sobre o cadastro indevido.',
    'sms.wallet.modal.notRecognized.confirmButton': 'Não reconheço cadastro',
    'sms.modal.linkExplain.title': 'Por que recebi esse link?',
    'sms.modal.linkExplain.text.1': 'A pessoa que realizou uma compra com o cartão de <strong>final {{lastDigits}}</strong> te enviou o pedido de verificação através da confirmação de identidade por selfie.',
    'sms.modal.linkExplain.text.2': 'Essa validação da compra serve tanto para proteger você, o titular do cartão, quanto quem está realizando o pedido.',
    'sms.modal.linkExplain.text.3': 'Caso <strong>não reconheça essa compra</strong>, selecione a opção "Não reconheço compra" que informaremos ao estabelecimento.',
    'sms.wallet.modal.linkExplain.text.1': 'A pessoa que realizou o cadastro do cartão de <strong>final {{lastDigits}}</strong> te enviou o pedido de verificação através da confirmação de identidade por selfie.',
    'sms.wallet.modal.linkExplain.text.2': 'Essa validação do cadastro serve tanto para proteger você, o titular do cartão, quanto quem irá utilizar diretamente na carteira digital.',
    'sms.wallet.modal.linkExplain.text.3': 'Caso <strong>não reconheça esse cadastro</strong>, selecione a opção "Não reconheço cadastro" que informaremos ao estabelecimento.',
    'sms.modal.linkExplain.confirmButton': 'Ok, entendi',
    'sms.modal.notOwner.title': 'O titular está presente?',
    'sms.modal.notOwner.text': 'Se você estiver próximo à pessoa titular, por favor, passe o dispositivo para essa pessoa prosseguir com a verificação.',
    'sms.modal.notOwner.confirmButton': 'Continuar verificação',
    'sms.modal.notOwner.closeButton': 'Finalizar verificação',
    'sms.modal.selfieNotFound.title': 'Selfie não encontrada',
    'sms.modal.selfieNotFound.text': 'Finalize a captura no dispositivo móvel para seguir com a validação.',
    'sms.modal.selfieNotFound.confirmButton': 'Ok, entendi',
    'sms.alt.warningSymbol': 'Símbolo de aviso',
    'chooseLanguage': 'Escolha o idioma',
    'chooseLanguage.PT': 'Português',
    'chooseLanguage.EN': 'English',
    'chooseLanguage.ES': 'Español',
    'chooseLanguage.KK': 'қазақ',
    'sms.dataHistory.title': 'Saiba quem acessou seus dados',
    'sms.dataHistory.subTitle': 'Confira quais empresas você permitiu que acessassem seus dados para autenticar sua identidade e proteger suas compras online.',
    'sms.dataHistory.link': 'Conhecer o Histórico de Acesso',
    'sms.popUpPermissions.title': 'Para seguir, vamos te pedir as permissões de acesso abaixo:',
    'sms.popUpPermissions.geolocalization.title': 'Localização',
    'sms.popUpPermissions.geolocalization.text': 'Usamos esse dado para aumentar a confiança na validação.',
    'sms.popUpPermissions.camera.title': 'Câmera',
    'sms.popUpPermissions.camera.text': 'Usamos essa permissão para capturar a foto do seu rosto.',
    'sms.popUpPermissions.continueButton': words.Continue,

    'sms.sharedSteps.sharingVerification.helpPermission.buttonTryAgain': 'Tentar novamente',
    'sms.sharedSteps.sharingVerification.helpPermission.titleInstructions': 'Instruções para <strong>permissão de câmera</strong>',
    'sms.sharedSteps.sharingVerification.helpPermission.titleWithBrowser': 'Saiba como permitir acesso à câmera no <br/> <icon/> <strong>{{name}}</strong>',
    'sms.sharedSteps.sharingVerification.helpPermission.titleWithoutBrowser': 'Saiba como permitir acesso à câmera no <strong>{{name}}</strong>',
    'sms.sharedSteps.sharingVerification.helpPermission.android': 'Android',
    'sms.sharedSteps.sharingVerification.helpPermission.ios': 'iOS',
    'sms.sharedSteps.sharingVerification.helpPermission.windows': 'Windows',
    'sms.sharedSteps.sharingVerification.helpPermission.macOS': 'macOS',
    'sms.sharedSteps.sharingVerification.helpPermission.titleNoPermission': 'Entenda como pode realizar essa permissão',
    'sms.sharedSteps.sharingVerification.helpPermission.subtitleNoPermission': 'Selecione o modelo utilizado e siga as instruções',
    'sms.sharedSteps.sharingVerification.helpPermission.haveOS': 'Tenho {{name}}',
    'sms.sharedSteps.sharingVerification.helpPermission.instructionPermission': 'Instruções de permissão',
    'sms.sharedSteps.sharingVerification.helpPermission.googleChrome': 'Google Chrome',
    'sms.sharedSteps.sharingVerification.helpPermission.samsunginternet': 'Samsung Internet',
    'sms.sharedSteps.sharingVerification.helpPermission.safari': 'Safari',
    'sms.sharedSteps.sharingVerification.helpPermission.mozillaFirefox': 'Mozilla Firefox',
    'sms.sharedSteps.sharingVerification.helpPermission.otherBrowser': 'Outro navegador',
    'sms.sharedSteps.sharingVerification.helpPermission.browser': 'Navegador',
    'sms.sharedSteps.sharingVerification.helpPermission.tips.mobileAndroidChrome.1': 'À direita da barra de endereço, toque no três pontos <icon/>',
    'sms.sharedSteps.sharingVerification.helpPermission.tips.mobileAndroidChrome.2': 'Toque em Configurações <icon/>',
    'sms.sharedSteps.sharingVerification.helpPermission.tips.mobileAndroidChrome.3': 'Desça a tela e selecione a opção Configurações do site',
    'sms.sharedSteps.sharingVerification.helpPermission.tips.mobileAndroidChrome.4': 'Toque em Câmera e ative a permissão <icon/>',
    'sms.sharedSteps.sharingVerification.helpPermission.tips.mobileAndroidChrome.5': 'Caso o link <strong>https://idpay.unico.io</strong> esteja bloqueado, selecione-o e aperte em Permitir',
    'sms.sharedSteps.sharingVerification.helpPermission.tips.mobileAndroidSamsungBrowser.1': 'À direita da barra inferior, selecione os três traços <icon/>',
    'sms.sharedSteps.sharingVerification.helpPermission.tips.mobileAndroidSamsungBrowser.2': 'Selecione a opção Configurações <icon/>',
    'sms.sharedSteps.sharingVerification.helpPermission.tips.mobileAndroidSamsungBrowser.3': 'Desça a página e selecione a opção Sites e downloads',
    'sms.sharedSteps.sharingVerification.helpPermission.tips.mobileAndroidSamsungBrowser.4': 'Toque em Permissões de site e ligue a Câmera <icon />',
    'sms.sharedSteps.sharingVerification.helpPermission.tips.mobileAndroidGenericBrowse.1': 'Abra o aplicativo Configurações <icon/>',
    'sms.sharedSteps.sharingVerification.helpPermission.tips.mobileAndroidGenericBrowse.2': 'Desça a tela e selecione Aplicativos',
    'sms.sharedSteps.sharingVerification.helpPermission.tips.mobileAndroidGenericBrowse.3': 'Procure pelo nome do navegador que está utilizando',
    'sms.sharedSteps.sharingVerification.helpPermission.tips.mobileAndroidGenericBrowse.4': 'Toque em Permissões e selecione Câmera <icon/>',
    'sms.sharedSteps.sharingVerification.helpPermission.tips.mobileAndroidGenericBrowse.5': 'Selecione a opção Permitir durante o uso do app e volte para salvar sua edição',
    'sms.sharedSteps.sharingVerification.helpPermission.tips.mobileIosChrome.1': 'Abra o aplicativo Ajustes',
    'sms.sharedSteps.sharingVerification.helpPermission.tips.mobileIosChrome.2': 'Desça a tela e toque em Chrome',
    'sms.sharedSteps.sharingVerification.helpPermission.tips.mobileIosChrome.3': 'Deixe a linha Câmera ativa <icon/>',
    'sms.sharedSteps.sharingVerification.helpPermission.tips.mobileIosChrome.4': 'Recomece a captura e aceite o novo pedido de permissão',
    'sms.sharedSteps.sharingVerification.helpPermission.tips.mobileIosSafari.1': 'Abra o aplicativo Ajustes',
    'sms.sharedSteps.sharingVerification.helpPermission.tips.mobileIosSafari.2': 'Desça a tela e toque em Safari',
    'sms.sharedSteps.sharingVerification.helpPermission.tips.mobileIosSafari.3': 'Ao final da página, selecione Câmera e toque em permitir',
    'sms.sharedSteps.sharingVerification.helpPermission.tips.mobileIosGenericBrowser.1': 'Abra o aplicativo Ajustes',
    'sms.sharedSteps.sharingVerification.helpPermission.tips.mobileIosGenericBrowser.2': 'Desça a tela e toque no nome do navegador que está utilizando',
    'sms.sharedSteps.sharingVerification.helpPermission.tips.mobileIosGenericBrowser.3': 'Selecione a opção Câmera e toque em permitir <icon1 /> ou ativar <icon2 />',
    'sms.sharedSteps.sharingVerification.helpPermission.tips.mobileIosGenericBrowser.4': 'Recomece a captura e aceite o novo pedido de permissão',
    'sms.sharedSteps.sharingVerification.helpPermission.tips.desktopWindowsChrome.1': 'Clique no erro de permissão ou no <strong>ícone de cadeado</strong> <icon/> à esquerda da barra de endereço.',
    'sms.sharedSteps.sharingVerification.helpPermission.tips.desktopWindowsChrome.2': 'Clique em <strong>Configurações do site</strong> <icon/>',
    'sms.sharedSteps.sharingVerification.helpPermission.tips.desktopWindowsChrome.3': 'Clique em <strong>Privacidade e Segurança</strong> <icon/> e depois em <strong>Câmera</strong>, selecione <strong>”Permitir”</strong>.',
    'sms.sharedSteps.sharingVerification.helpPermission.tips.desktopWindowsMozilla.1': 'Clique no ícone de menu no canto superior direito da janela e selecione "Opções".',
    'sms.sharedSteps.sharingVerification.helpPermission.tips.desktopWindowsMozilla.2': 'Na barra lateral esquerda, clique em "Privacidade e Segurança".',
    'sms.sharedSteps.sharingVerification.helpPermission.tips.desktopWindowsMozilla.3': 'Desça até a seção "Permissões" e encontre a opção "Câmera".',
    'sms.sharedSteps.sharingVerification.helpPermission.tips.desktopWindowsMozilla.4': 'Deixe a opção ”Pedir para Ativar a Câmera” marcada.',
    'sms.sharedSteps.sharingVerification.helpPermission.tips.desktopWindowsMozilla.5': 'Caso o link desta página esteja bloqueado, clique em ”Exceções” e adicione para continua',
    'sms.sharedSteps.sharingVerification.helpPermission.tips.desktopWindowsGenericBrowser.1': 'Clique no erro de permissão ou no <strong>ícone de cadeado</strong> <icon/> à esquerda da barra de endereço.',
    'sms.sharedSteps.sharingVerification.helpPermission.tips.desktopWindowsGenericBrowser.2': 'Clique em <strong>Configurações do site</strong> <icon/>',
    'sms.sharedSteps.sharingVerification.helpPermission.tips.desktopWindowsGenericBrowser.3': 'Clique em <strong>Privacidade e Segurança</strong> <icon/> e depois em <strong>Câmera</strong>, selecione <strong>”Permitir”</strong>.',
    'sms.sharedSteps.sharingVerification.helpPermission.tips.desktopMac.1': 'Clique no erro de permissão ou no <strong>ícone de cadeado</strong> <icon/> à esquerda da barra de endereço.',
    'sms.sharedSteps.sharingVerification.helpPermission.tips.desktopMac.2': 'Ative a <strong>Câmera</strong> com o seletor <icon/> ou clique em <strong>“continuar permitindo acesso à sua câmera”</strong>.',
    'sms.sharedSteps.sharingVerification.helpPermission.tips.desktopMac.3': 'Se a câmera está desligada nas configurações do Mac, clique em <strong>Abrir Preferências</strong>, ou acesse as <strong>Ajustes</strong> <icon/> do seu computador.',
    'sms.sharedSteps.sharingVerification.helpPermission.tips.desktopMac.4': 'Acesse <icon/> <strong>Câmera</strong>.',
    'sms.sharedSteps.sharingVerification.helpPermission.tips.desktopMac.5': 'Localize o navegador que você utiliza e habilite o seletor <icon/>',
    'sms.sharedSteps.sharingVerification.helpPermission.pageTitle': 'Você não permitiu o acesso à câmera',
    'sms.sharedSteps.sharingVerification.helpPermission.pageSubtitle': 'Sem o acesso, não podemos confirmar sua identidade e validar a compra.',
    'sms.sharedSteps.sharingVerification.helpPermission.buttonNotWorked': 'Ainda não funcionou?',
    'sms.sharedSteps.sharingVerification.helpPhotoQuality.titleTipPhoto': 'Mantenha seu rosto no centro da forma oval e siga as instruções abaixo:',
    'sms.sharedSteps.sharingVerification.helpPhotoQuality.pageTitle': 'Não conseguimos validar sua identidade',
    'sms.sharedSteps.sharingVerification.helpPhotoQuality.pageSubtitle': 'A qualidade da foto pode ter interferido na verificação. Por favor, tente novamente.',
    'sms.sharedSteps.sharingVerification.helpPhotoQuality.tip1': 'Dicas para aumentar a qualidade da captura',
    'sms.sharedSteps.sharingVerification.helpPhotoQuality.tip2': 'Segure seu telefone na altura dos olhos.',
    'sms.sharedSteps.sharingVerification.helpPhotoQuality.tip3': 'Encontre um lugar bem iluminado e retire acessórios como chapéu, óculos, etc.',

    'sms.component.timer': 'Você tem <time/> para tirar a foto',

    // Frictionless flow
    'sms.component.summary.timer': 'A verificação irá começar em',
    'sms.notify.presentationFrictionless.title': 'Vamos fazer a verificação de segurança da compra abaixo',
    'sms.notify.presentationFrictionless.buttonContinueVerification': 'Começar a verificação',
    'sms.notify.congratulationsFrictionless.title': 'Compra reconhecida',
    'sms.notify.congratulationsFrictionless.subtitle': 'Agora é só aguardar o retorno da loja sobre o status deste pedido.',
    'sms.notify.loadingAuthentication.success': 'Identidade verificada',
    'sms.notify.loadingAuthentication.checking': 'Verificando informações',
    'sms.sharedSteps.prepare.frictionless.title': 'Para finalizar, tire uma foto do titular do cartão utilizado na compra',
    'sms.sharedSteps.prepare.frictionless.continueButton': 'Tirar foto',
    'sms.sharedSteps.prepare.frictionless.owner': buttons.owner,
    'sms.tooltip.explainFrictionless': 'A verificação serve para sabermos se essa compra é verdadeira e vinculada a você ou a outra pessoa. Assim, podemos evitar fraudes com o cartão utilizado.',
    'sms.tooltip.explainShare': 'A pessoa que realizou a compra com o cartão de <strong>final {{lastDigits}}</strong> te enviou o pedido de verificação de segurança. Para finalizá-lo, confirme sua identidade através de uma foto do seu rosto.',

    // Help Chat
    'sms.helpChat.header': 'Ajuda',
    'sms.helpChat.title': 'Opa! Em breve isso será possível.',
    'sms.helpChat.text1': 'Ainda estamos trabalhando para que você possa sanar suas dúvidas como desejar.',
    'sms.helpChat.text2': 'Por enquanto, em caso de dúvidas, acesse nosso <link/>.',
    'sms.helpChat.link': 'Portal de Ajuda',
    'sms.helpChat.category1': 'É confiável tirar a foto?',
    'sms.helpChat.category2': 'Não consigo tirar a foto',
    'sms.helpChat.category3': 'Quem é Unico?',
    'sms.helpChat.category4': 'Tenho outra dúvida',

    //Iframe One Page
    'sms.notify.prepareOnePage.title': 'Selfie do titular do cartão',
    'sms.notify.prepareOnePage.button.captureSelfie': 'Tirar Selfie',
    'sms.notify.presentationFrictionless.checkFinished': 'Verificação concluída',
    'sms.notify.presentationFrictionless.checking': 'Verificando informações',
    'sms.newFooter': 'Ao continuar você concorda com os <termUse /> e <termPrivacyPolicy /> da Unico',
    'sms.newFooter.termUse': 'Termos de Uso',
    'sms.newFooter.termPrivacyPolicy': 'Política de Privacidade',
  }
}

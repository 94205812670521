import { useLDClient } from 'launchdarkly-react-client-sdk'

export const identifyClientLD = ({ userId, tenantId }) => {
    const ldClient = useLDClient()
  
    if (userId && tenantId) {
      const userContext = {
        kind: 'user',
        key: userId,
        clientId: tenantId
      }
  
      ldClient.identify(userContext);
    }
  }
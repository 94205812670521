import * as Sentry from '@sentry/react';

export const initSentry = (release) => {

  if (!release) {
    try {
      const version = require(process.env.NX_PUBLIC_PATH_APP + "/src/assets/scripts/.version").default;
      const productName = process.env.NX_PUBLIC_PRODUCT_NAME.toLowerCase().replaceAll(' ', '-');

      release = productName ? `${productName}@${version}` : version;
    } catch (e) {
      /* */
    }
  }

  Sentry.init({
    dsn: process.env.NX_PUBLIC_SENTRY_DSN,
    integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    environment: process.env.NODE_ENV,
    release,
  });
};

export const initSentryWithData = (sentryData) => {
  var {
    release,
    tracesSampleRate = 1.0,
    replaysSessionSampleRate = 0.1,
    replaysOnErrorSampleRate = 1.0,
    ...options
  } = sentryData || {};

  if (!release) {
    try {
      const version = require('../../../../../'+process.env.NX_PUBLIC_PATH_APP + '/.version.json').default;
      const productName = process.env.NX_PUBLIC_PRODUCT_NAME.toLowerCase().replaceAll(
        ' ',
        '-'
      );

      release = productName ? `${productName}@${version}` : version;
    } catch (e) {
       /* */
    }
  }

  Sentry.init({
    dsn: process.env.NX_PUBLIC_SENTRY_DSN,
    integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
    environment: process.env.NODE_ENV,
    tracesSampleRate,
    replaysOnErrorSampleRate,
    replaysSessionSampleRate,
    ...options,
    release,
  });
};

export const captureException = (error) => {
  Sentry.captureException(error);
}


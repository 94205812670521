import { BLUE_100, NEUTRAL_GRAY_100, NEUTRAL_GRAY_300, NEUTRAL_GRAY_400 } from "../../defaults/colors";
import styled from "styled-components";

export const Wrapper = styled.div`
  max-width: 560px;
  opacity: 1;
  transition: .2s opacity ease;

  ${({ loadingData }) => loadingData && `
    opacity: .4;
  `}
`;

export const ButtonsArea = styled.div`
  display: flex;
  justify-content: end;
  gap: 10px;
  margin-top: 24px;
`;

export const DataDisplay = styled.p`
  color: ${NEUTRAL_GRAY_400};
  font-size: 18px;
  line-height: 28px;
  margin: 8px 0;

  ${({ loadingData }) => loadingData && `
    background: ${NEUTRAL_GRAY_100};
    color: ${NEUTRAL_GRAY_100};
    max-width: 250px;
    min-height: 28px;
  `}
`;

export const LongDataDisplay = styled.p`
  color: ${NEUTRAL_GRAY_400};
  font-size: 18px;
  line-height: 28px;
  margin: 8px 0;

  ${({ loadingData }) => loadingData && `
    background: ${NEUTRAL_GRAY_100};
  `}
`;

export const LogoDisplayWrapper = styled.div`
  align-items: center;
  border: 1px dashed ${NEUTRAL_GRAY_300};
  border-radius: 8px;
  padding: 10px 14px;
  display: flex;
  gap: 12px;
  opacity: 1;
  transition: .3s opacity ease;

  & svg {
    filter: invert(32%) sepia(55%) saturate(3889%) hue-rotate(204deg) brightness(95%) contrast(94%);
  }

  & p {
    margin: 0;
  }

  ${({ loadingData }) => loadingData && `
    opacity: .3;

    & p {
      display: none;
    }
  `}
`;

export const LogoDisplay = styled.div`
  align-items: center;
  background: ${BLUE_100};
  border-radius: 4px;
  display: flex;
  height: 56px;
  justify-content: center;
  width: 56px;

  ${({ imageURL }) => imageURL && `
    background: url(${imageURL});
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  `}
`;
